<template>
  <div class="w-full h-full flex justify-items-center">
    <UContainer>
      <slot></slot>
      <UNotifications />
    </UContainer>
  </div>
</template>
<script setup lang="ts">
const nuxtApp = useNuxtApp();

const loading = ref(false);

nuxtApp.hook("page:start", () => {
  loading.value = true;
});

nuxtApp.hook("page:finish", () => {
  loading.value = false;
});
</script>
<style lang="scss">
.dark {
  background: rgb(var(--color-gray-900) / 1);
  --color-primary-DEFAULT: var(--color-gray-900);
  --ui-background: var(--color-gray-900);
  --ui-foreground: var(--color-gray-200);
}
</style>